<template>
  <div id="contac-list">
    <v-sheet color="transparent">
      <v-slide-group
        v-model="tab"
        mandatory
        :show-arrows="$vuetify.breakpoint.smAndDown"
        :mobile-breakpoint="$vuetify.breakpoint.thresholds.md + 200"
      >
        <v-slide-item
          v-for="tab in tabs"
          :key="tab.icon"
          v-slot="{ active, toggle }"
        >
          <v-btn
            class="mx-2 px-3"
            :input-value="active"
            active-class="secondary white--text"
            depressed
            text
            @click="toggle"
          >
            <v-icon
              size="20"
              class="me-2"
            >
              {{ tab.icon }}
            </v-icon>
            <span>{{ $t(tab.title) }}</span>
          </v-btn>
        </v-slide-item>
      </v-slide-group>
    </v-sheet>

    <v-tabs-items
      v-model="tab"
      class="mt-5 px-2 overflow-visible"
    >
      <v-tab-item>
        <v-card>
          <v-row class="px-2 ma-0 text-center">
            <v-col
              cols="12"
              sm="12"
              md="3"
            >
              <v-text-field
                v-model="searchQuery"
                :placeholder="t('search')"
                outlined
                hide-details
                dense
              >
              </v-text-field>
            </v-col>

            <v-spacer />

            <v-col
              v-if="hasPermission(116)"
              cols="12"
              sm="2"
            >
              <v-btn
                color="secondary"
                block
                @click="onCreate"
              >
                {{ t('tooltip.new') }} {{ t('contacts.contact') }}
              </v-btn>
            </v-col>
          </v-row>

          <v-data-table
            :headers="computedTableColumns"
            :items="listTable"
            item-key="id"
            :options.sync="options"
            :loading="loading"
            :loading-text="t('table.loading_text')"
            :no-data-text="t('table.no_data')"
            :header-props="{sortByText: t('table.sort_by')}"
            :footer-props="{itemsPerPageText: t('table.item_page')}"
          >
            <template #[`item.fullname`]="{item}">
              <div class="d-flex align-center">
                <v-avatar
                  color="primary"
                  :class="item.avatar ? '' : 'v-avatar-light-bg primary--text'"
                  size="32"
                >
                  <v-img
                    v-if="item.avatar"
                    :src="item.avatar"
                  ></v-img>
                  <span
                    v-else
                    class="font-weight-medium"
                  >{{ avatarText(item.fullname) }}</span>
                </v-avatar>

                <div class="d-flex flex-column ms-3">
                  <span class="text--primary font-weight-semibold text-truncate">
                    {{ item.fullname }}
                  </span>
                </div>
              </div>
            </template>

            <template #[`item.actions`]="{item}">
              <!-- <v-tooltip
                v-if="hasPermission(115)"
                bottom
              >
                <template #activator="{ on: tooltip, attrs }">
                  <v-icon
                    size="20"
                    class="me-2"
                    color="info"
                    v-bind="attrs"
                    v-on="{ ...tooltip }"
                    @click="onShow(item)"
                  >
                    {{ icons.mdiEyeOutline }}
                  </v-icon>
                </template>
                <span>{{ $t('tooltip.detail') }}</span>
              </v-tooltip> -->

              <v-tooltip
                v-if="hasPermission(117) && !item.user_app_id"
                bottom
              >
                <template #activator="{ on: tooltip, attrs }">
                  <v-icon
                    size="20"
                    class="me-2"
                    color="success"
                    v-bind="attrs"
                    v-on="{ ...tooltip }"
                    @click="onEdit(item)"
                  >
                    {{ icons.mdiPencilOutline }}
                  </v-icon>
                </template>
                <span>{{ $t('tooltip.edit') }}</span>
              </v-tooltip>

              <v-tooltip
                v-if="hasPermission(118)"
                bottom
              >
                <template #activator="{ on: tooltip, attrs }">
                  <v-icon
                    size="20"
                    class="me-2"
                    color="error"
                    v-bind="attrs"
                    v-on="{ ...tooltip }"
                    @click="onDelete(item.id)"
                  >
                    {{ icons.mdiDeleteOutline }}
                  </v-icon>
                </template>
                <span>{{ $t('tooltip.delete') }}</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-card>
      </v-tab-item>

      <v-tab-item>
        <v-card>
          <v-row class="px-2 ma-0 text-center">
            <v-col
              cols="12"
              sm="12"
              md="3"
            >
              <v-text-field
                v-model="searchQueryCat"
                :placeholder="t('search')"
                outlined
                hide-details
                dense
              >
              </v-text-field>
            </v-col>

            <v-spacer />

            <v-col
              v-if="hasPermission(125)"
              cols="12"
              sm="3"
            >
              <v-btn
                color="secondary"
                block
                @click="onCreate"
              >
                {{ t('tooltip.new') }} {{ t('Category') }}
              </v-btn>
            </v-col>
          </v-row>

          <v-data-table
            :headers="computedTableColumnsCategories"
            :items="listTableCategories"
            item-key="id"
            :options.sync="optionsCategories"
            :loading="loading"
            :loading-text="t('table.loading_text')"
            :no-data-text="t('table.no_data')"
            :header-props="{sortByText: t('table.sort_by')}"
            :footer-props="{itemsPerPageText: t('table.item_page')}"
          >
            <template #[`item.status`]="{item}">
              <v-chip
                small
                :color="resolveStatusVariant(item.status)"
                :class="`${resolveStatusVariant(item.status)}--text`"
                class="v-chip-light-bg font-weight-semibold text-capitalize"
              >
                {{ resolveStatusText(item.status) }}
              </v-chip>
            </template>

            <template #[`item.actions`]="{item}">
              <!-- <v-tooltip
                v-if="hasPermission(124)"
                bottom
              >
                <template #activator="{ on: tooltip, attrs }">
                  <v-icon
                    size="20"
                    class="me-2"
                    color="info"
                    v-bind="attrs"
                    v-on="{ ...tooltip }"
                    @click="onShow(item)"
                  >
                    {{ icons.mdiEyeOutline }}
                  </v-icon>
                </template>
                <span>Details</span>
              </v-tooltip> -->

              <v-tooltip
                v-if="hasPermission(126)"
                bottom
              >
                <template #activator="{ on: tooltip, attrs }">
                  <v-icon
                    size="20"
                    class="me-2"
                    color="success"
                    v-bind="attrs"
                    v-on="{ ...tooltip }"
                    @click="onEdit(item)"
                  >
                    {{ icons.mdiPencilOutline }}
                  </v-icon>
                </template>
                <span>Edit</span>
              </v-tooltip>

              <v-tooltip
                v-if="hasPermission(127)"
                bottom
              >
                <template #activator="{ on: tooltip, attrs }">
                  <v-icon
                    size="20"
                    class="me-2"
                    color="error"
                    v-bind="attrs"
                    v-on="{ ...tooltip }"
                    @click="onDelete(item.id)"
                  >
                    {{ icons.mdiDeleteOutline }}
                  </v-icon>
                </template>
                <span>Delete</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-card>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
/* eslint-disable import/no-cycle */
/* eslint-disable object-curly-newline */
import {
  mdiPlus,
  mdiDeleteOutline,
  mdiPencilOutline,
  mdiEyeOutline,
  mdiAccountMultipleOutline,
  mdiTableAccount,
} from '@mdi/js'
import { ref, computed, onMounted } from '@vue/composition-api'
import { useUtils } from '@core/libs/i18n'
import { useRouter } from '@core/utils'

import { deleteContact, deleteContactCategory } from '@api'

// sidebar
import { avatarText } from '@core/utils/filter'
import { error, success } from '@core/utils/toasted'
import Swal from 'sweetalert2'

import useContactsList from './useContactsList'

export default {
  setup() {
    const { t } = useUtils()
    const { router, route } = useRouter()

    const {
      listTable,
      computedTableColumns,
      listTableCategories,
      computedTableColumnsCategories,
      searchQuery,
      searchQueryCat,
      loading,
      options,
      optionsCategories,
      filters,

      fetchContacts,
      fetchContactCategories,
      resolveStatusVariant,
      resolveStatusText,
      hasPermission,
      setFilters,
      setOptions,
      getFilterBySubmodule,
    } = useContactsList()

    const tab = ref(0)
    const tabs = ref([
      {
        title: 'Contacts',
        icon: mdiAccountMultipleOutline,
        disabled: false,
        hide: false,
      },
      {
        title: 'Categories',
        icon: mdiTableAccount,
        disabled: false,
        hide: false,
      },
    ])

    const statusOptions = computed(() => [
      { text: t('status.active'), value: 'A' },
      { text: t('status.inactive'), value: 'I' },
    ])

    const onCreate = () => {
      router.push({
        name: 'views-contact-form',
        params: {
          option: 1,
          pageParams: options.value,
          filterParams: filters.value,
          isContact: tab.value === 0 ? 'contact' : 'category',
        },
      })
    }

    const onEdit = item => {
      router.push({
        name: 'views-contact-form',
        params: {
          id: item.id,
          dataParams: item,
          option: 3,
          pageParams: options.value,
          filterParams: filters.value,
          isContact: tab.value === 0 ? 'contact' : 'category',
        },
      })
    }

    const onShow = item => {
      router.push({
        name: 'views-contact-form',
        params: {
          id: item.id,
          dataParams: item,
          option: 2,
          pageParams: options.value,
          filterParams: filters.value,
          isContact: tab.value === 0 ? 'contact' : 'category',
        },
      })
    }

    const onDelete = async id => {
      Swal.fire({
        title: 'Estas seguro?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Si, Eliminar!',
        cancelButtonText: 'No',
      }).then(async result => {
        if (result.isConfirmed) {
          let response = null
          if (tab.value === 0) response = await deleteContact(id)
          else response = await deleteContactCategory(id)
          if (response && response.ok) {
            success(response.message)
            if (tab.value === 0) await fetchContacts()
            else await fetchContactCategories()
          } else {
            error(response.message.text)
          }
        }
      })
    }

    onMounted(async () => {
      const { filterParams, pageParams } = route.value.params
      setOptions(pageParams)
      setFilters(filterParams)

      const contactsFilters = getFilterBySubmodule('views-contact-list', 'contacts')
      const categoriesFilters = getFilterBySubmodule('views-contact-list', 'categories')
      if (contactsFilters.search) searchQuery.value = contactsFilters.search
      if (categoriesFilters.search) searchQueryCat.value = categoriesFilters.search

      await fetchContacts()
      await fetchContactCategories()
    })

    return {
      listTable,
      computedTableColumns,
      listTableCategories,
      computedTableColumnsCategories,
      searchQuery,
      searchQueryCat,
      statusOptions,
      loading,
      options,
      optionsCategories,
      filters,

      tab,
      tabs,

      onCreate,
      onEdit,
      onShow,
      onDelete,
      avatarText,
      fetchContacts,
      fetchContactCategories,
      hasPermission,
      resolveStatusVariant,
      resolveStatusText,
      setFilters,
      setOptions,

      // i18n
      t,

      // icons
      icons: {
        mdiDeleteOutline,
        mdiPlus,
        mdiEyeOutline,
        mdiPencilOutline,
      },
    }
  },
}
</script>
